var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.selected != null)?_c('charging-info',{attrs:{"charging":_vm.selected},on:{"on:close":_vm.close_info}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.chargings,"loading":_vm.loading_charges,"options":_vm.options,"multi-sort":false,"item-key":"id","loading-text":"Загрузка... Подождите","no-data-text":"Зарядные сессии не найдены","item-class":_vm.rowClass,"footer-props":{
      'items-per-page-options': [15, 25, 50, 100, -1],
      showFirstLastPage: true,
      'items-per-page-text': 'Всего на странице',
    }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.charge_info},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.charging_status(item.status))+" ")]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.created_at).locale("ru-RU").utc().format("LLL"))+" ")]}},{key:"item.updated_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.updated_at).locale("ru-RU").utc().format("LLL"))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }