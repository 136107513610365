<template>
  <v-dialog v-model="show_info" @click:outside="close">
    <v-card>
      <v-card-title class="text-h5 lighten-2">
        Зарядная сессии {{ charging.id }} -
        {{ capitalizeFirstLetter(charging_status(charging.status)) }}
      </v-card-title>

      <v-card-text>
        <v-row class="py-4">
          <v-col cols="6"
            >Дата зарядки
            {{
              $moment(charging.created_at).locale("ru-RU").utc().format("LLL")
            }}</v-col
          >
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-chip color="green" v-bind="attrs" v-on="on"
                  >{{ charging.payment_amount }} &#8381;</v-chip
                >
              </template>
              <span>{{ charging_status(charging.status) }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="py-4">
          <v-col cols="3">
            <div class="text-h5 black--text">Операция</div>
          </v-col>
          <v-row class="py-4">
            <v-col class="col-lg-4 col-md-3 col-sm-6 col-xs-6">
              <div class="item">
                <span class="name-info">Станция</span>
                <span class="text">{{
                  charging.charge_point.point_number
                }}</span>
              </div>
            </v-col>
            <v-col class="col-lg-4 col-md-3 col-sm-6 col-xs-6">
              <div class="item">
                <span class="name-info">Коннектор</span>
                <span class="text">{{
                  charging.reserved_connector.connector_type
                }}</span>
              </div>
            </v-col>
            <v-col class="col-lg-4 col-md-3 col-sm-6 col-xs-6">
              <div class="item">
                <span class="name-info">Статус</span>
                <span class="text">{{
                  capitalizeFirstLetter(charging_status(charging.status))
                }}</span>
              </div>
            </v-col>
          </v-row>
        </v-row>
        <v-divider></v-divider>
        <v-row class="py-4">
          <v-col cols="3">
            <div class="text-h5 black--text">Клиент</div>
          </v-col>
          <v-row class="py-4">
            <v-col class="col-lg-4 col-md-3 col-sm-6 col-xs-6">
              <div class="item">
                <span class="name-info">ID клиента</span>
                <span class="text">{{ charging.client.id }}</span>
              </div>
            </v-col>
            <v-col class="col-lg-4 col-md-3 col-sm-6 col-xs-6">
              <div class="item">
                <span class="name-info">Номер клиента</span>
                <span class="text">{{ charging.client.phone }}</span>
              </div>
            </v-col>
            <v-col class="col-lg-4 col-md-3 col-sm-6 col-xs-6">
              <div class="item">
                <span class="name-info">Email</span>
                <span class="text">{{
                  charging.client.email ? charging.client.email : "-"
                }}</span>
              </div>
            </v-col>
            <v-col class="col-lg-4 col-md-3 col-sm-6 col-xs-6">
              <div class="item">
                <span class="name-info">IP адрес</span>
                <span class="text">{{ charging.client.current_login_ip }}</span>
              </div>
            </v-col>
          </v-row>
        </v-row>
        <v-divider></v-divider>
        <v-row class="py-4">
          <v-col cols="3">
            <div class="text-h5 black--text">Статистика</div>
          </v-col>
          <v-row class="py-4">
            <v-col class="col-lg-4 col-md-3 col-sm-6 col-xs-6">
              <div class="item">
                <span class="name-info">Передано энергии</span>
                <span class="text">{{ charging.power_amount }} Вт</span>
              </div>
            </v-col>
            <v-col class="col-lg-4 col-md-3 col-sm-6 col-xs-6">
              <div class="item">
                <span class="name-info">Стоимость</span>
                <span class="text">{{ charging.payment_amount }} руб</span>
              </div>
            </v-col>
            <v-col class="col-lg-4 col-md-3 col-sm-6 col-xs-6">
              <div class="item">
                <span class="name-info">Бронирование</span>
                <span class="text">-</span>
              </div>
            </v-col>
          </v-row>
        </v-row>
        <v-divider></v-divider>
        <v-row class="py-4">
          <v-col cols="3">
            <div class="text-h5 black--text">Даты</div>
          </v-col>
          <v-row class="py-4">
            <v-col class="col-lg-4 col-md-3 col-sm-6 col-xs-6">
              <div class="item">
                <span class="name-info">Начало</span>
                <span class="text">{{
                  $moment(charging.created_at)
                    .locale("ru-RU")
                    .utc()
                    .format("LLL")
                }}</span>
              </div>
            </v-col>
            <v-col class="col-lg-4 col-md-3 col-sm-6 col-xs-6">
              <div class="item">
                <span class="name-info">Окончание</span>
                <span class="text">{{
                  $moment(charging.updated_at)
                    .locale("ru-RU")
                    .utc()
                    .format("LLL")
                }}</span>
              </div>
            </v-col>
          </v-row>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close">Закрыть </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ChargingInfo",
  props: ["charging"],
  computed: {
    show_info() {
      return this.$store.getters.SHOW_CHARGE_INFO;
    },
  },
  methods: {
    close() {
      this.$store.dispatch("show_charge_info", false);
      this.$emit("close");
    },
    charging_status(status_code) {
      switch (status_code) {
        case "reserved":
          return "Забронирована";
        case "charging":
          return "Идет зарядка";
        case "end":
          return "Зарядка завершена";
        case "interrupted":
          return "Зарядка прервана";
        case "paid":
          return "Оплачено";
        case "waiting_payment":
          return "Ожидание оплаты";
        case "preparing":
          return "Ожидание подключения";
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
};
</script>

<style scoped lang="scss"></style>
