<template>
  <v-container fluid>
    <charging-info
      v-if="selected != null"
      :charging="selected"
      @on:close="close_info"
    ></charging-info>
    <v-data-table
      :headers="headers"
      :items="chargings"
      :loading="loading_charges"
      :options.sync="options"
      :multi-sort="false"
      item-key="id"
      loading-text="Загрузка... Подождите"
      no-data-text="Зарядные сессии не найдены"
      @click:row="charge_info"
      :item-class="rowClass"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [15, 25, 50, 100, -1],
        showFirstLastPage: true,
        'items-per-page-text': 'Всего на странице',
      }"
    >
      <template v-slot:item.status="{ item }">
        {{ charging_status(item.status) }}
      </template>
      <template v-slot:item.created_at="{ item }">
        {{ $moment(item.created_at).locale("ru-RU").utc().format("LLL") }}
      </template>
      <template v-slot:item.updated_at="{ item }">
        {{ $moment(item.updated_at).locale("ru-RU").utc().format("LLL") }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import chargingInfo from "@/views/ChargingInfo";

export default {
  name: "Chargings",
  components: {
    chargingInfo,
  },
  data: () => {
    return {
      selected: null,
      headers: [
        {
          text: "Номер зарядки",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Номер станции",
          align: "start",
          sortable: true,
          value: "charge_point.point_number",
        },
        {
          text: "Коннектор",
          align: "start",
          sortable: false,
          value: "reserved_connector.connector_type",
        },
        {
          text: "Передано, Вт",
          align: "start",
          sortable: true,
          value: "power_amount",
        },
        {
          text: "Стоимость, P",
          align: "start",
          sortable: true,
          value: "payment_amount",
        },
        {
          text: "Время начала",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Время окончания",
          align: "start",
          sortable: true,
          value: "updated_at",
        },
        {
          text: "Статус",
          align: "start",
          sortable: true,
          value: "status",
        },
      ],
      options: {
        sortBy: ["id"],
        sortDesc: [true],
        page: 1,
      },
    };
  },
  created() {
    this.load_charges();
  },
  watch: {
    options: {
      handler() {
        this.load_charges();
      },
      deep: true,
    },
  },
  computed: {
    params() {
      return {
        ...this.options,
      };
    },
    loading_charges() {
      return this.$store.getters.LOADING_CHARGES;
    },
    chargings() {
      return this.$store.getters.CHARGINGS;
    },
  },
  methods: {
    load_charges() {
      this.$store.dispatch("list_charges", this.params);
    },
    charging_status(status_code) {
      switch (status_code) {
        case "reserved":
          return "Забронирована";
        case "charging":
          return "Идет зарядка";
        case "end":
          return "Зарядка завершена";
        case "interrupted":
          return "Зарядка прервана";
        case "paid":
          return "Оплачено";
        case "waiting_payment":
          return "Ожидание оплаты";
        case "preparing":
          return "Ожидание подключения";
      }
    },
    charge_info(item) {
      this.selected = item;
      this.$store.dispatch("show_charge_info", true);
    },
    close_info() {
      this.selected = null;
    },
    rowClass() {
      const rowClass = "clickable";
      return rowClass;
    },
  },
};
</script>

<style scoped lang="scss"></style>
